// src/components/ValidatorList.js
import React from 'react';

const ValidatorList = ({ validators, onValidatorClick, selectedValidators }) => {
  return (
    <div className="sidebar">
      <ul>
        {validators.map((v, index) => (
          <li 
            key={index} 
            onClick={() => onValidatorClick(v)} 
            style={{ 
              color: selectedValidators.includes(v.validatorName) ? 'gray' : 'black', // Change color if selected
              cursor: selectedValidators.includes(v.validatorName) ? 'not-allowed' : 'pointer' // Change cursor
            }} 
          >
            {v.validatorName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ValidatorList;
