// src/App.js
import React, { useState, useEffect } from 'react';
import Graph from './components/Graph';
import ValidatorList from './components/ValidatorList';
import useNeo4jConnector from './hooks/useNeo4jConnector';
import './styles/App.css';

const App = () => {
  const [data, setData] = useState({ nodes: [], links: [] });
  const [validators, setValidators] = useState([]);
  const [selectedValidators, setSelectedValidators] = useState([]);
  const neo4jConnector = useNeo4jConnector();

  useEffect(() => {
    const fetchData = async () => {
      if (neo4jConnector) {
        // Fetch validators
        let validatorData = await neo4jConnector.fetchValidators();
        validatorData = validatorData.filter(v => v.validatorName);
        validatorData.sort((a, b) => a.validatorName.localeCompare(b.validatorName));
        setValidators(validatorData);

        // Fetch graph data for specific validators
        const initialValidators = ['Enigma', 'Bro_n_Bro', 'POSTHUMAN'];
        const initialGraphDataPromises = initialValidators.map(validator => 
          neo4jConnector.fetchGraphData(validator)
        );

        // Resolve all graph data promises
        const initialGraphDataResults = await Promise.all(initialGraphDataPromises);

        // Combine graph data
        const combinedNodes = new Map();
        const combinedLinks = [];

        initialGraphDataResults.forEach(graphData => {
          graphData.nodes.forEach(node => combinedNodes.set(node.id, node));
          combinedLinks.push(...graphData.links);
        });

        // Update state with combined graph data
        setData({ nodes: Array.from(combinedNodes.values()), links: combinedLinks });

        // Set selected validators to the initially loaded ones
        setSelectedValidators(initialValidators);
      }
    };
    fetchData();
  }, [neo4jConnector]);

  const handleValidatorClick = async (validator) => {
    if (neo4jConnector && !selectedValidators.includes(validator.validatorName)) {
      const newGraphData = await neo4jConnector.fetchGraphData(validator.validatorName, data);
      setData((prevData) => {
        const nodesMap = new Map(prevData.nodes.map(node => [node.id, node]));
        newGraphData.nodes.forEach(node => nodesMap.set(node.id, node));
  
        const linksSet = new Set(prevData.links.map(link => `${link.source.id}-${link.target.id}`));
        const combinedLinks = [...prevData.links];
  
        newGraphData.links.forEach(link => {
          const sourceNode = nodesMap.get(link.source);
          const targetNode = nodesMap.get(link.target);
          const key = `${link.source}-${link.target}`;
  
          if (!linksSet.has(key) && sourceNode && targetNode) {
            linksSet.add(key);
            combinedLinks.push({ source: sourceNode, target: targetNode });
          }
        });
  
        return { nodes: Array.from(nodesMap.values()), links: combinedLinks };
      });

      // Add clicked validator to the selected validators
      setSelectedValidators(prev => [...prev, validator.validatorName]);
    }
  };

  const clearGraph = () => {
    setData({ nodes: [], links: [] }); // Clear the graph data
    setSelectedValidators([]); // Reset selected validators
  };
  
  return (
    <div className="app-container">
      <div className="map-container">
        <Graph data={data} />
      </div>
      <div className="sidebar">
        <div className="sidebar-header">
          <h3>Validators List</h3>
          <button onClick={clearGraph} className="clear-button">Clear Nodes</button>
        </div>
        <ValidatorList validators={validators} onValidatorClick={handleValidatorClick} selectedValidators={selectedValidators} />
      </div>
    </div>
  );
};

export default App;
