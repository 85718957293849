import React, { useEffect, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import '../styles/App.css';

const Graph = ({ data }) => {
  const [walletCounts, setWalletCounts] = useState({});
  const [clickedNode, setClickedNode] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');
  const [dashOffset, setDashOffset] = useState(0);

  useEffect(() => {
    if (data.nodes && data.links) {
      // Count connections for each wallet node without modifying data
      const counts = {};
      data.links.forEach(link => {
        const walletId = link.source.id || link.source; // Ensure source is an ID
        if (!counts[walletId]) {
          counts[walletId] = 0;
        }
        counts[walletId]++;
      });
      setWalletCounts(counts); // Update walletCounts state
    
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDashOffset((prevOffset) => (prevOffset + 1) % 20); // Adjust speed and reset point
    }, 50); // Controls animation speed
    return () => clearInterval(interval);
  }, []);

  const handleNodeClick = (node) => {
    setClickedNode(node); // Set the clicked node to show its name and details
  };

  const shortenAddress = (address) => {
    return `${address.slice(0, 8)}...${address.slice(-4)}`; // Shorten address format
  };

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address); // Copy the full address
    setCopyMessage('Copied!'); // Set the copy message
    setTimeout(() => setCopyMessage(''), 2000); // Clear the message after 2 seconds
  };

  return (
    <div style={{ display: 'flex', overflow: 'hidden', width: '100vw', height: '100vh' }}>
      <ForceGraph2D
        graphData={data}
        width={window.innerWidth}
        height={window.innerHeight}
        d3Force="charge"
        d3Charge={-300} // Reduce repulsive force to bring nodes closer
        linkDistance={10} // Reduce link distance for tighter connections
        minZoom={0.2}
        maxZoom={4}
        enablePanInteraction={true}
        enableNavigationControls={true}
        onNodeClick={handleNodeClick}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const isValidator = node.type === 'validator';
          const isSharedWallet = node.type === 'wallet' && walletCounts[node.id] > 1; // Check if wallet is shared
          
          
          // Determine radius for validators based on totalStaked, scaled between 20 and 50
          const minRadius = 20;
          const maxRadius = 50;
          const scaledStake = isValidator ? Math.min(maxRadius, Math.max(minRadius, node.totalStaked / 2000)) : 5; // Adjust divisor as needed for scaling
          const radius = isValidator ? scaledStake : 5;

          // Set color with transparency for validator nodes
          if (isValidator) {
            ctx.fillStyle = 'rgba(128, 0, 128, 0.5)'; // Purple with 50% transparency
          } else if (isSharedWallet) {
            ctx.fillStyle = '#FFD700'; // Yellow for shared wallets
          } else {
            ctx.fillStyle = '#FF6B6B'; // Soft red for regular wallets
          }


          ctx.beginPath();
          ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI);
          ctx.fill();

          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(255, 105, 180, 1)';
          ctx.stroke();

          if (isValidator) {
            ctx.font = `${8 / globalScale}px Arial`;
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            ctx.fillText(node.id, node.x, node.y + 3);
          }
        }}
        linkCanvasObject={(link, ctx, globalScale) => {
          ctx.setLineDash([5, 10]); // Dashed pattern [dash length, gap length]
          ctx.lineDashOffset = -dashOffset; // Animate by updating offset
          ctx.strokeStyle = '#FF69B4'; // Hot pink color for dashed link
          ctx.lineWidth = 1.5; // Adjust thickness
          ctx.beginPath();
          ctx.moveTo(link.source.x, link.source.y);
          ctx.lineTo(link.target.x, link.target.y);
          ctx.stroke();
          ctx.setLineDash([]); // Reset dash style
        }}

        // Allow dragging for wallet nodes
        onNodeDrag={(node) => {
          if (node.type === 'wallet') { // Only allow dragging for wallet nodes
            node.fx = node.x;
            node.fy = node.y;
          }
        }}
        onNodeDragEnd={(node) => {
          if (node.type === 'wallet') { // Release position lock on drag end
            node.fx = null;
            node.fy = null;
          }
        }}
      />

      <div className="sidebar_little">
        {clickedNode ? (
          <div>
            {clickedNode.type === 'validator' ? (
              <>
                <h4 className="sidebar-title">{clickedNode.id}</h4>
                <p>Total Staked: {Math.round(clickedNode.totalStaked) || 'N/A'}</p>
              </>
            ) : (
              <div>
                <h4 className="sidebar-title">Wallet Details</h4>
                <p className="sidebar-wallet-address">
                  Wallet Address: {shortenAddress(clickedNode.id)}
                </p>
                <p>Wallet Staking: {Math.round(clickedNode.stakingAmount) || 'N/A'}</p>
                <button onClick={() => handleCopy(clickedNode.id)} className="copy-button">Copy Address</button>
              </div>
            )}
            {copyMessage && <p className="copy-message">{copyMessage}</p>}
          </div>
        ) : (
          <p>Click on a node to see details.</p>
        )}
      </div>
    </div>
  );
};

export default Graph;
