// src/hooks/useNeo4jConnector.js
import { useEffect, useState } from 'react';
import { Neo4jConnector } from '../utils/Neo4jConnector';

const useNeo4jConnector = () => {
  const [neo4jConnector, setNeo4jConnector] = useState(null);

  useEffect(() => {
    const connector = new Neo4jConnector('bolt+s://neo4j.enigma-validator.com', 'neo4j', 'RXu5CWSJh_byf8qdwKMVBcfKeifSW6p4rCsmWUBOf98');
    setNeo4jConnector(connector);

    return () => {
      connector.close();
    };
  }, []);

  return neo4jConnector;
};

export default useNeo4jConnector;
