// src/utils/Neo4jConnector.js
import neo4j from 'neo4j-driver';

export class Neo4jConnector {
  constructor(uri, user, password) {
    this.driver = neo4j.driver(uri, neo4j.auth.basic(user, password));
  }

  async fetchValidators() {
    const session = this.driver.session();
    const query = `MATCH (v:Validator) RETURN DISTINCT v.name AS validatorName`;
    try {
      const result = await session.run(query);
      return result.records.map(record => ({
        validatorName: record.get('validatorName')
      }));
    } finally {
      session.close();
    }
  }

  async fetchGraphData(validatorName, existingData = { nodes: [], links: [] }) {
    const session = this.driver.session();
    const query = `
      MATCH (w:Wallet)-[r:DELEGATES_TO]->(v:Validator)
      WHERE (v.name = 'Enigma' AND ($validatorName IS NULL OR size($validatorName) = 0))
      OR (size($validatorName) <> 0 AND v.name IN $validatorName)
      RETURN v AS validator, w AS wallet, r.amount AS amount
    `;

    
    const params = { validatorName: Array.isArray(validatorName) ? validatorName : [validatorName] };
  
    try {
      const result = await session.run(query, params);
      const nodesMap = new Map(existingData.nodes.map(node => [node.id, node])); // Initialize with existing nodes
      const links = [...existingData.links]; // Initialize with existing links
  
      result.records.forEach(record => {
        const validator = record.get('validator').properties;
        const wallet = record.get('wallet').properties;
        const amount = parseFloat(record.get('amount')) || 0;
  
        // Add or update validator node
        if (!nodesMap.has(validator.name)) {
          nodesMap.set(validator.name, {
            id: validator.name,
            type: 'validator',
            color: 'blue',
            totalStaked: 0,
          });
        }
        nodesMap.get(validator.name).totalStaked += amount;
  
        // Add or update wallet node with accumulated staking amount
        if (!nodesMap.has(wallet.address)) {
          nodesMap.set(wallet.address, {
            id: wallet.address,
            type: 'wallet',
            color: 'red',
            stakingAmount: 0,
          });
        }
        nodesMap.get(wallet.address).stakingAmount += amount;
  
        // Add link with staked amount if not already present
        if (!links.find(link => link.source === wallet.address && link.target === validator.name)) {
          links.push({ source: wallet.address, target: validator.name, value: amount });
        }
      });
  
      return { nodes: Array.from(nodesMap.values()), links };
    } finally {
      session.close();
    }
  }
  
  
  
  
  
  

  close() {
    this.driver.close();
  }
}
